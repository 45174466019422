import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AlertInterface} from "../components/ui/alert/alert.component";


@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private isDisplayed: BehaviorSubject<AlertInterface | null> = new BehaviorSubject<AlertInterface | null>(null);
  public isDisplayed$: Observable<AlertInterface | null> = new Observable();

  constructor() {
    this.isDisplayed$ = this.isDisplayed.asObservable();
  }

  public display(type: "error" | "warning" | "info" | "success", text: string, func?: Function, funcLabel?: string): void {
    this.isDisplayed.next({type, text, func, funcLabel});
  }

  public hide(): void {
    this.isDisplayed.next(null);
  }
}
